<template>
  <div class="connect-account-wrap">
    <NavBack/>
    <div class="fixed-background sticky-header">
      <MenuAndLogo></MenuAndLogo>
    </div>
    <div class="has-padding">
      <div class="page-title has-text-weight-semibold has-text-centered">Add new bank</div>
      <div class="main has-background-white">
        <div class="hint has-text-centered">Linking a bank account can be used to withdraw dividends and add funds into your addy wallet for your investments.</div>
        <PlaidInitializer />
        <div class="manual-link has-text-centered">
          <i class="fa fa-info-circle has-text-blue is-clickable" aria-hidden="true" @click="showInstantFundsModalAction"></i>
        </div>
        <div class="manual-link has-text-centered">
          <span class="has-text-primary is-clickable" @click="toManualProcess">Want to manually add a bank?</span>
          <p class="px-4 py-4">(Manual pairing is a lengthy process with a high failure rate. Multiple attempts may be required. Takes 1-2 weeks to complete.)</p>
        </div>
      </div>
      <div class="bottom">
        <span class="back has-text-primary has-text-weight-bold is-clickable" @click="goBack">Back</span>
      </div>
    </div>
    <Flash title="Notice!" content="You must link your bank account to add funds." :show="showFlash" :hideFlash="hideFlashAction"></Flash>
    <StandardModal
      :showModal="showInstantFundsModal"
      :closeModal="closeInstantFundsModalAction"
      title="Instant Funds ⚡️"
      buttonTitle="Done"
      readMoreUrl="https://www.addyinvest.com/2021/06/11/introducing-instant-credit/"
      >
      <div class="modal-text">
        <div class="mb-4">Depending on your membership, there is a $500 limit on instant available funds with new deposits.
          This can be spread across multiple deposits or used all in one go. You can use this immediately
          on share purchases only. Your limit resets after your deposit is processed.</div>
        <div>If you deposit more than your instant funds limit, the remainder of the funds will
          be available in your addy wallet within 7 business days.</div>
      </div>
    </StandardModal>
  </div>
</template>
<script>
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import PlaidInitializer from '@components/plaid/initializer.vue'
import Flash from '@components/modal/slide-down-flash.vue'
import StandardModal from '@components/modal/standard-modal.vue'

export default {
  data() {
    return {
      showFlash: false,
      showInstantFundsModal: false,
    }
  },
  mounted() {
    this.showFlashAction()
  },
  components: {
    NavBack,
    MenuAndLogo,
    PlaidInitializer,
    Flash,
    StandardModal,
  },
  methods: {
    goBack() {
      window.history.back()
    },
    toManualProcess() {
      this.$router.push('/wallet/accounts/manual')
    },
    showFlashAction() {
      if (this.$route.query.requiredBankAccount !== 'true') {return}
      this.showFlash = true

      // Sanitize URL
      const query = Object.assign({}, this.$route.query)
      delete query.requiredBankAccount
      const queryString = Object.keys(query).map((key) => {
        return encodeURIComponent(key) + '=' + encodeURIComponent(query[key])
      }).join('&')
      history.pushState({}, null, '#' + this.$route.path + '?' + queryString)
    },
    hideFlashAction() {
      this.showFlash = false
    },
    showInstantFundsModalAction() {
      this.showInstantFundsModal = true
    },
    closeInstantFundsModalAction() {
      this.showInstantFundsModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';

.connect-account-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  padding-bottom: 80px;
  min-height: 100vh;
  .has-padding {
    padding: 0 25px;
    .page-title {
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 16px;
      letter-spacing: 0.03em;
    }
    .main {
      min-height: 426px;
      border-radius: 12px;
      box-shadow: 0px 20px 40px rgba(215, 145, 51, 0.15);
      .hint {
        padding: 20px 21px 64px;
        font-size: 16px;
        line-height: 19px;
      }
      .manual-link {
        padding-top: 14px;
        font-size: 16px;
        p {
          font-size: 14px;
        }
      }
    }
    .bottom {
      padding-top: 44px;
      .back {
        font-family: 'Proxima Nova', sans-serif;
        font-size: 16px;
        padding-left: 18px;
      }
    }
  }
}
@media only screen and (min-width: $min-viewport-width) {
  .connect-account-wrap {
    padding-top: 19px;
    min-height: $min-height-mobile-container;
    max-width: $mobile-wrapper-width;
    position: unset;
    .bottom {
      display: none;
    }
  }
}
</style>
